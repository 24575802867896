import algoliasearch from "algoliasearch"
import _ from "lodash"
import pluralize from "pluralize"
import qs from "qs"
import voca from "voca"

export const SEARCH_CLIENT = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)

export const MAP_TOGGLE = {
  map: "map",
  grid: "grid",
}

export const INDEX_NAME = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`

export const DEFAULT_AREA = "kent"

export const sortOptions = [
  { value: INDEX_NAME, label: "Most Recent" },
  {
    value: INDEX_NAME + "_price_desc",
    label: "Highest Price",
  },
  {
    value: INDEX_NAME + "_price_asc",
    label: "Lowest Price",
  },
]

export const getAreaPath = areas => {
  let url = ""
  const areaSlugs =
    typeof areas === "string"
      ? areas
      : areas?.map(area => (area.slug ? area.slug : area))

  if (!areaSlugs || _.isEmpty(areaSlugs)) {
    url = `in-${DEFAULT_AREA}/`
  } else if (typeof areaSlugs === "string") {
    url = `in-${areaSlugs}/`
  } else if (areaSlugs.length > 0) {
    url = `in-${areaSlugs.join("-and-")}/`
  }

  return url
}

export const getProppertyTypePath = propertyType => {
  let url = ""
  if (propertyType) {
    url = `type-${propertyType}/`
  }
  return url
}

export const getDeveloperPath = developerName => {
  let url = ""
  if (developerName) {
    url = `developed-by-${developerName}/`
  }
  return url
}

export const getBedroomPath = bedrooms => {
  let url = ""
  if (bedrooms) {
    const bedroomText = parseInt(bedrooms) === 1 ? "bedroom" : "bedrooms"
    url = parseInt(bedrooms) === 0 ? "studio/" : `${bedrooms}-${bedroomText}/`
  }
  return url
}

export const getBathroomPath = bathrooms => {
  let url = ""
  if (bathrooms) {
    url = `${bathrooms}-and-more-bathrooms/`
  }
  return url
}

export const getPricePath = (minPrice, maxPrice) => {
  let url = ""
  if (minPrice && maxPrice) {
    url = `between-${minPrice}-and-${maxPrice}/`
  } else if (maxPrice) {
    url = `under-${maxPrice}/`
  } else if (minPrice) {
    url = `above-${minPrice}/`
  }
  return url
}

export const getSizePath = (minSize, maxSize) => {
  let url = ""
  if (minSize && maxSize) {
    url = `from-${minSize}-to-${maxSize}-sqft/`
  } else if (maxSize) {
    url = `below-${maxSize}-sqft/`
  } else if (minSize) {
    url = `more-than-${minSize}-sqft/`
  }
  return url
}

export const getSortPath = sort => {
  let url = ""
  if (sort?.includes("price_asc")) {
    url = `sortby-price-asc/`
  } else if (sort?.includes("price_desc")) {
    url = `sortby-price-desc/`
  }
  return url
}

export const getPagePath = page => {
  let url = ""
  if (page > 1) {
    url = `page-${page}/`
  }
  return url
}

export const addTraillingSlash = url => {
  if (url && url[url.length - 1] !== "/") {
    url = url + "/"
  }
  return url
}

// Create URL
export const createURL = (props, state, location) => {
  const isDefaultRoute =
    !state.query &&
    !state.SortBy &&
    state.page === 1 &&
    state.menu &&
    state.range &&
    !state.range.price &&
    !state.range.bedroom &&
    !state.range.bathroom &&
    !state.range.floorarea_max &&
    state.refinementList &&
    !state.refinementList?.search_areas?.length === 0

  if (isDefaultRoute) return ""

  const areaPath = getAreaPath(
    state.query ||
      state.refinementList?.display_address ||
      state.refinementList?.search_areas
  )
  const bedroomPath = getBedroomPath(state.range?.bedroom?.min)
  const bathroomPath = getBathroomPath(state.range?.bathroom?.min)
  const pricePath = getPricePath(
    state.range?.price?.min,
    state.range?.price?.max
  )
  const sizePath = getSizePath(
    state.range?.floorarea_max?.min,
    state.range?.floorarea_max?.max
  )
  const pagePath = getPagePath(state.page)
  const propertypath = getProppertyTypePath(state.menu?.building)
  const developerPath = getDeveloperPath(state.refinementList?.developer_slug)
  const sortPath = getSortPath(state.sortBy)

  let myUrl = `${areaPath}${propertypath}${developerPath}${bedroomPath}${bathroomPath}${pricePath}${sizePath}${sortPath}${pagePath}`
  myUrl = addTraillingSlash(myUrl)

  return `${location}${myUrl}`
}
// Create URL

// URL to searchstate
export const searchStateToUrl = (props, searchState, location) =>
  searchState ? `${createURL(props, searchState, location)}` : ""

export const urlToSearchState = async (location, pSubPath) => {
  const defaultArea = DEFAULT_AREA

  if (location) {
    const pathname = location.pathname
    let pathUri_main = pathname.split(pSubPath)
    var query = ""
    var areaVal = ""
    var bedVal = 10
    var bathVal = 10
    var minSizeVal = ""
    var maxSizeVal = ""
    var minpriceVal = ""
    var maxpriceVal = ""
    var pageVal = ""
    var propertyval = ""
    var sortVal = ""
    var sortVal_filt = ""
    var developerval = ""

    if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")

      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
          continue
        }

        // Property type
        if (pathUri[vi].indexOf("type-") >= 0) {
          propertyval = pathUri[vi].replace("type-", "")
        }

        // Area
        if (
          pathUri[vi].indexOf("in-") >= 0 &&
          pathUri[vi].indexOf("in-") <= 1
        ) {
          let areaStr = pathUri[vi]
          areaVal = areaStr.replace("in-", "")
          if (areaVal === defaultArea) {
            areaVal = ""
          }
          // if (multiArea) {
          //   areaVal = areaVal.split("-and-")
          // }
        }

        // Price
        if (
          pathUri[vi].indexOf("between-") >= 0 ||
          pathUri[vi].indexOf("above-") >= 0 ||
          pathUri[vi].indexOf("under-") >= 0
        ) {
          let priceFilt1 = pathUri[vi].split("above-")
          if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split("under-")
          if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split("between-")
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-and-")
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
          }
        }

        if (pathUri[vi].indexOf("-bedroom") >= 0) {
          bedVal = pathUri[vi].replace("-bedrooms", "").replace("-bedroom", "")
        }

        if (pathUri[vi].indexOf("studio") >= 0) {
          // its studio bedroom
          bedVal = 0
        }
        if (pathUri[vi].indexOf("developed-by-") >= 0) {
          developerval = pathUri[vi].replace("developed-by-", "")
        }

        // Size
        if (
          pathUri[vi].indexOf("from-") >= 0 ||
          pathUri[vi].indexOf("more-than-") >= 0 ||
          pathUri[vi].indexOf("below-") >= 0
        ) {
          let priceFilt1 = pathUri[vi].replace("-sqft", "").split("more-than-")
          if (priceFilt1[1]) {
            minSizeVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].replace("-sqft", "").split("below-")
          if (priceFilt2[1]) {
            maxSizeVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].replace("-sqft", "").split("from-")
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].replace("-sqft", "").split("-to-")
            minSizeVal = priceFilt4[0]
            maxSizeVal = priceFilt4[1]
          }
        }

        //   Bathrooms
        if (pathUri[vi].indexOf("-and-more-bathrooms") >= 0) {
          bathVal = pathUri[vi].replace("-and-more-bathrooms", "")
        }

        // Sort by
        if (pathUri[vi].indexOf("sortby-") >= 0) {
          sortVal_filt = pathUri[vi].replace("sortby-", "")

          if (sortVal_filt === "price-asc") {
            sortVal = INDEX_NAME + "_price_asc"
          }

          if (sortVal_filt === "price-desc") {
            sortVal = INDEX_NAME + "_price_desc"
          }
        }

        // Page
        if (pathUri[vi].indexOf("page") >= 0) {
          pageVal = pathUri[vi].replace("page-", "")
        }
      }
    }
  }

  // Property type
  if (propertyval) {
    query += `&menu[building]=` + propertyval
  }

  //developer name
  if (developerval) {
    query += `&refinementList[developer_slug]=` + developerval
  }

  // Area
  if (Array.isArray(areaVal) && areaVal.length > 0) {
    query += `&query=` + areaVal[0]
  } else if (areaVal) {
    query += `&query=` + areaVal
  }

  // Price
  if (minpriceVal) {
    query += `&range[price][min]=` + minpriceVal
  }
  if (maxpriceVal) {
    query += `&range[price][max]=` + maxpriceVal
  }

  // Size
  if (minSizeVal) {
    query += `&range[floorarea_max][min]=` + minSizeVal
  }
  if (maxSizeVal) {
    query += `&range[floorarea_max][max]=` + maxSizeVal
  }

  // Bedrooms
  if (bedVal == 0) {
    query += `&range[bedroom][min]=` + 0
    query += `&range[bedroom][max]=` + 0
  } else if (bedVal < 10) {
    query += `&range[bedroom][min]=` + bedVal
  }

  // Bathrooms
  if (bathVal == 0) {
    query += `&range[bathroom][min]=` + 0
    query += `&range[bathroom][max]=` + 0
  } else if (bathVal < 10) {
    query += `&range[bathroom][min]=` + bathVal
  }

  // Sort by
  if (sortVal) {
    query += `&sortBy=` + sortVal
  }

  // Page
  if (pageVal) {
    query += `&page=${pageVal}`
  }

  return qs.parse(query)
}
// URL to searchstate

export const getPriceText = (minPrice, maxPrice) => {
  const formatPrice = (price) => Number(price).toLocaleString('en-US');
  let text = ""
  if (minPrice && maxPrice) {
    text = `between £${formatPrice(minPrice)} and £${formatPrice(maxPrice)}`
  } else if (minPrice) {
    text = `above £${formatPrice(minPrice)}`
  } else if (maxPrice) {
    text = `below £${formatPrice(maxPrice)}`
  }
  return text
}

export const getBuildingText = building => {
  let text = pluralize(voca.capitalize(building?.replace("-", " ")))
  return text
}

export const getBedroomText = bedroom => {
  let text = ""
  if (bedroom === 0) {
    text = "studio"
  } else if (bedroom > 0) {
    const bedroomText = bedroom > 1 ? "bedrooms" : "bedroom"
    text = `with more than ${bedroom} ${bedroomText}`
  }
  return text
}

export const getSizetext = (minSize, maxSize) => {
  let text = ""
  if (minSize && maxSize) {
    text = `between ${minSize} and ${maxSize} sqft`
  } else if (minSize) {
    text = `above ${minSize} sqft`
  } else if (maxSize) {
    text = `below ${maxSize} sqft`
  }
  return text
}

export const convertSlugToCapitalizedText = slug => {
  if (!slug) return ""
  return slug
    .split("-")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(" ")
}

export const getAreasText = areas => {
  let text = ""
  if (!areas) return text

  if (!Array.isArray(areas)) areas = [areas]

  if (areas.length > 0) {
    text = `in ${areas
      .map(area => convertSlugToCapitalizedText(area))
      .join(", ")
      .replace(/,([^,]*)$/, " and$1")}`
  }

  return text
}

export const getH1Text = ({
  searchState,
  pType,
  capitalize,
  isCommercial,
  isResidential,
  isNewHomes,
  newDevelopments,
}) => {
  const propertyText = isCommercial
    ? "Commercial Properties"
    : newDevelopments === "secondary-newhomes"
    ? "New Developments"
    : isNewHomes
    ? "New Homes"
    : "Properties"
  const defaultAreaText = voca.capitalize(DEFAULT_AREA)
  const buyRent = pType === "sales" ? "for sale" : "to rent"

  let h1Text = `${propertyText} ${buyRent} in ${defaultAreaText}`
  if (_.isEmpty(searchState)) return h1Text

  const areas =
    getAreasText(
      searchState.query || searchState.refinementList?.search_areas
    ) || `in ${defaultAreaText}`
  let building = getBuildingText(searchState.menu?.building) || propertyText
  building = capitalize ? building : building.toLowerCase()
  const price = getPriceText(
    searchState.range?.price?.min,
    searchState.range?.price?.max
  )
  const bedroom = getBedroomText(searchState.range?.bedroom?.min)
  const size = getSizetext(
    searchState.range?.floorarea_max?.min,
    searchState.range?.floorarea_max?.max
  )

  h1Text = `${building} ${buyRent} ${areas} ${price} ${bedroom} ${size}`
  h1Text = voca.trim(h1Text)

  return h1Text
}

export const getSeoDesc = ({
  searchState,
  pType,
  isResidential,
  isCommercial,
  isNewHomes,
  newDevelopments,
}) => {
  // console.log(searchState, "state")
  const h1Text = getH1Text({
    searchState,
    pType,
    isResidential,
    isCommercial,
    isNewHomes,
    newDevelopments,
  })
  const action = pType === "sales" ? "for sale" : "to rent"
  const defaultAreaText = voca.capitalize(DEFAULT_AREA)
  const areas =
    getAreasText(searchState.refinementList?.search_areas) ||
    `in ${defaultAreaText}`
  const building = searchState?.menu?.building
  const bedroom = searchState?.range?.bedroom?.min
  const minPrice = searchState?.range?.price?.min
  const maxPrice = searchState?.range?.price?.max

  let title = `Explore our stunning range of ${h1Text}. Contact our new homes team to find the right property ${action} ${areas}.`
  if (isNewHomes) {
    if (areas) {
      title = `Explore our stunning range of ${h1Text}. Contact our new homes team to find the right property ${action} ${areas}.`
    }
    if (building) {
      title = `Searching for a newly built ${h1Text}? Christopher Hodgson's new homes professionals are here to help.`
    }
    if (bedroom) {
      title = `Find ${h1Text} here. Contact Christopher Hodgson's new home professionals in ${areas} today to get assistance.`
    }
    if (minPrice || maxPrice) {
      title = `Locate the best ${h1Text} with Christopher HodgsonEstate agents. Book an appointment with one of our new homes professional in ${areas}.`
    }
    if ((minPrice || maxPrice) && building) {
      title = `Check the latest collection of new ${h1Text} with Christopher Hodgsonestate agents and letting agents in ${areas}.`
    }
    if ((minPrice || maxPrice) && bedroom) {
      title = `Explore the list of ${h1Text} with Christopher Hodgsonand Request a viewing for the property that interests you.`
    }
    if (building && bedroom) {
      title = `Find the latest ${h1Text}. Contact Christopher Hodgsonestate agents and letting agents in ${areas}, to arrange a viewing.`
    }
    if ((minPrice || maxPrice) && building && bedroom) {
      title = `Find new ${h1Text} with Christopher Hodgson. Contact one of our new homes professional today to get assistance in finding the best property in ${areas}.`
    }
  } else {
    if (areas) {
      title = `Explore our range of ${h1Text}. Contact our property experts to find the right property ${action} ${areas}.`
    }
    if (building) {
      title = `Looking for a ${h1Text}? Property experts from Christopher Hodgsonare here to help.`
    }
    if (bedroom) {
      title = `Explore ${h1Text} here. Contact our expert estate agents today to get assistance in finding the right property in ${areas}.`
    }
    if (minPrice || maxPrice) {
      title = `Locate the right ${h1Text} with Christopher Hodgson. Book an appointment with one of our properties experts ${areas}.`
    }
    if ((minPrice || maxPrice) && building) {
      title = `Check out the latest collection of ${h1Text} with Christopher Hodgson ${areas}.`
    }
    if ((minPrice || maxPrice) && bedroom) {
      title = `Explore the list of ${h1Text} with Christopher Hodgson and request a viewing for the properties that interests you.`
    }
    if (building && bedroom) {
      title = `Find the latest collection of ${h1Text}. Contact Christopher Hodgson ${areas}, to arrange a viewing.`
    }
    if ((minPrice || maxPrice) && building && bedroom) {
      title = `Explore ${h1Text} available with Christopher Hodgson. Contact one of our real estate agents for assistance in finding your properties ${areas}.`
    }
  }
  return title
}

export const sortSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
    "&:hover": {
      border: "none",
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#555C65",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#484848",
    // fontFamily: "Gilroy",
    fontSize: "14px",
    lineHeight: "140%",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#484848",
    // fontFamily: "Gilroy",
    fontSize: "14px",
    lineHeight: "140%",
  }),
}

export const popOverHeightMobile = 39
export const popOverHeight = 47

export const stateToParams = (state, algoliaState) => ({
  areas: algoliaState?.query || "",
  bedroom: algoliaState?.range?.bedroom?.min || "",
  pType: state?.pType || "",
  type: algoliaState?.menu?.building || "",
  minPrice: algoliaState?.range?.price?.min || "",
  maxPrice: algoliaState?.range?.price?.max || "",
  department: state?.pDepartment || "",
  search_type: algoliaState?.search_type || "",
  building: algoliaState?.menu?.building || "",
  propertyType: algoliaState?.menu?.building || "",
})
